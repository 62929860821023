<template>
    <section id="ft-contact-page" class="ft-contact-page-section page-padding">
		<div class="container">
			<div class="ft-contact-page-content">
				<div class="row">
					<div class="col-lg-6">
						<div class="ft-contact-page-text-wrapper">
							<div class="ft-section-title-2 headline pera-content">
								<span class="sub-title">Contact Us</span>
								<h2>Get in Touch And We’ll  Help
									Your Business
								</h2>
							</div>
							<div class="ft-contact-page-contact-info">
								<div class="ft-contact-cta-items d-flex">
									<div class="ft-contact-cta-icon d-flex align-items-center justify-content-center">
										<i class="fal fa-map-marker-alt"></i>
									</div>
									<div class="ft-contact-cta-text headline pera-content">
										<h3>Office address</h3>
										<p>{{ mainData.address2 }}</p>
									</div>
								</div>
								<div class="ft-contact-cta-items d-flex">
									<div class="ft-contact-cta-icon d-flex align-items-center justify-content-center">
										<i class="fas fa-phone-alt"></i>
									</div>
									<div class="ft-contact-cta-text headline pera-content">
										<h3>Telephone number</h3>
										<p>{{ mainData.contact_no }}</p> 
									</div>
								</div>
								<div class="ft-contact-cta-items d-flex">
									<div class="ft-contact-cta-icon d-flex align-items-center justify-content-center">
										<i class="far fa-envelope"></i>
									</div>
									<div class="ft-contact-cta-text headline pera-content">
										<h3>Mail address</h3>
										<p>{{ mainData.email }}</p> 
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="ft-contact-page-form-wrapper headline">
							<h3 class="text-center">Contact Us</h3>
							<form 
								id="contact-form2"
								@submit="sendmail2()"
								action="javascript:void(0)"
								class="contact-form"
								method="POST"
							>
								<div class="row">
									<div class="col-lg-6">
										<input type="text" name="name" id="name" placeholder="Your Name" required>
									</div>
									<div class="col-lg-6">
										<input type="email" name="email" id="email" placeholder="Your Email" required>
									</div>
									<div class="col-lg-6">
										<input type="text" name="phone" id="phone" placeholder="Phone" required>
									</div>
									<div class="col-lg-6">
										<input type="text" name="subject" id="subject" placeholder="Subject" required>
									</div> 
									<div class="col-lg-12">
										<textarea name="message" id="message" placeholder="Your Message" required></textarea>
									</div>
									<div class="col-lg-12">
										<button type="submit">Submit</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>	
</template>


<script>
import $ from "jquery";
import { Email } from "../../assets/js/smtp.js";
import swal from "sweetalert2";
window.Swal = swal;
import data from '../../data/data.json'
export default {
    data(){
        return{
			mainData: ""
		}
    },
	created(){
		this.mainData = data.companyDeatils
	},
	methods: {
    sendmail2() {
      var name = $("#name").val();
      var email = $("#email").val();
      var phone = $("#phone").val();
      var subject = $("#subject").val();
      var message = $("#message").val();
      var Body =
        "First Name: " +
        name +
        "<br>Email: " +
        email +
        "<br>Phone: " +
        phone +
        "<br>Subject: " +
        subject +
        "<br>Message: " +
        message;

      Email.send({
        SecureToken: "3152fc5e-458f-4f65-ada8-bdaf7f5b7a0b", 
		To: "info@winwinlines.com",
        From: "winwincontainerline@gmail.com",
        Subject: "New message on contact from " + name,
        Body: Body,
      }).then((message) => {
        if (message == "OK") {
          new swal(
            "Thank You For Message Us!",
            "We will contact you as soon as possible!",
            "success"
          );
          document.getElementById("contact-form2").reset();
        } else {
          console.error(message);
          new swal("Oops!", "Something went wrong, please try again!", "error");
          document.getElementById("contact-form2").reset();
        }
      });
    },
  },
}
</script>