<template>
    <breadCrumb/>
    <contact/>
</template>

<script>
import breadCrumb from '../components/topSection/topSection.vue'
import contact from '../components/contact/contact.vue'
export default {
    components: {
        breadCrumb,
        contact
    },
    data(){
        return{}
    }
}
</script>